<template>
	<div class="mpr-manager-container">
		<mpr-manager
			v-if="volumeData"
			ref="mprManager"
			:volume-data="volumeData"
			:is-jpeg="isJpeg"
			:view-data="viewData"
			:sync-window-levels="syncWindowLevels"
			:active-tools="activeTools"
			:switch-mode-active="switchModeActive"
			:layout="layout"
			:default-voi="defaultVoi"
			:initial-voi="initialVoi"
			:initial-slice="initialSlice"
			:slice-directionality="sliceDirectionality"
			:show-axis="showAxis"
			:show-overlay-text="showOverlayText"
			:show-image-orientation-markers="showImageOrientationMarkers"
			:volume-direction="volumeDirection"
			:overlay-information="overlayInformation"
			@hook:mounted="onManagerMounted"
			@switch="onSwitch"
			@toggle-fullscreen="onToggleFullscreen"
			@rotate="onRotate"
			@roll="onRoll"
			@thickness="onThickness"
			@activity="onActivity"
			@windowLevels="onWindowLevels"
			@update:switchModeActive="setSwitchModeActive"
			@performance-issues="onPerformanceIssues"
			@webglcontextlost="onWebGLContextLost"
		/>
		<div v-if="isLoading" class="load-container">
			<progress-bar v-if="loadProgress >= 0" class="load-progress" :width="loadProgress" />
			<div class="load-message">
				<h3 class="load-title">{{ loadTitle }}</h3>
				<p v-if="loadSubtitle" class="load-subtitle">{{ loadSubtitle }}</p>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'

import { MPRManager } from '@vtk/index.js'
import ProgressBar from '@/components/ProgressBar'
import { tryStartTour } from '@router/layouts/MprViewerTour'

export default {
	components: {
		mprManager: MPRManager,
		ProgressBar,
	},
	props: {
		index: {
			type: Number,
			required: true,
		},
		series: {
			type: Object,
			required: true,
		},
		asterisImageId: {
			type: String,
			required: true,
		},
		initialSlice: {
			type: Number,
			default: 1,
		},
		initialVoi: {
			type: Object,
			default: undefined,
		},
	},
	data() {
		return {
			// This component's data
			loadTitle: 'Initializing',
			loadSubtitle: 'Please Wait...',
		}
	},
	computed: {
		...mapState({
			volumeData: state => state.mpr.activeSeries.volumeData,
			viewData: state => state.mpr.activeSeries.viewData,
			syncWindowLevels: state => state.mpr.syncWindowLevels,
			isLoading: state => state.mpr.activeSeries.isLoading,
			loadProgress: state => state.mpr.activeSeries.loadProgress,
			layout: state => state.mpr.activeSeries.layout,
			switchModeActive: state => state.mpr.activeSeries.switchModeActive,
			defaultVoi: state => state.mpr.activeSeries.defaultVoi,
			activeTools: state => state.mpr.activeTools,
			showAxis: state => state.mpr.showAxisOverlay,
			showOverlayText: state => state.mpr.showOverlayText,
			showImageOrientationMarkers: state => state.mpr.showImageOrientationMarkers,
			volumeDirection: state => state.mpr.activeSeries.volumeDirection,
			sliceDirectionality: state => state.mpr.activeSeries.sliceDirectionality,
			isJpeg: state => state.mpr.activeSeries.isJpeg,
		}),
		image() {
			let asterisImageId = this.asterisImageId
			return this.series.images.find(x => x.imageId === asterisImageId && x.frameIndex === this.frameIndex)
		},
		overlayInformation() {
			const { fullName, simpleName, acquisitionTime } = this.series.images[0]

			return {
				...this.series.overlayInformation,
				fullName,
				simpleName,
				acquisitionTime,
			}
		},
	},
	watch: {
		series: {
			immediate: true,
			handler() {
				// Keep mpr active series in sync with parent series
				const { seriesId, modality } = this.series
				this.setActiveSeries({ seriesId, modality })
				window.setTimeout(() => {
					this.loadSeriesAsync()
				}, 100)
			},
		},
	},
	beforeDestroy() {
		this.clearActiveSeries()
	},
	methods: {
		...mapMutations({
			setActiveSeries: 'SET_MPR_ACTIVE_SERIES',
			clearActiveSeries: 'CLEAR_MPR_ACTIVE_SERIES',
			onSwitch: 'SWITCH_VIEWS',
			onToggleFullscreen: 'TOGGLE_MPR_VIEW_FULLSCREEN',
			onRotate: 'SET_MPR_SLICE_ROTATION',
			onRoll: 'SET_MPR_VIEW_ROLL',
			onActivity: 'SET_MPR_ACTIVE_VIEW',
			onWindowLevels: 'SET_MPR_WINDOW_LEVELS_TEXT',
			setSwitchModeActive: 'SET_MPR_SWITCH_MODE_ACTIVE',
			setViewComponent: 'SET_MPR_VIEW_COMPONENT',
			setOnResetCB: 'SET_ON_MPR_RESET_CB',
			setOnInvertCB: 'SET_MPR_INVERT_CB',
			setOnMoveSliceCB: 'SET_MPR_MOVESLICE_CB',
		}),
		...mapActions({
			loadVolumeFromSeries: 'loadVolumeFromSeries',
			onThickness: 'setThickness',
		}),
		onManagerMounted() {
			this.$nextTick(() => {
				// Register components with their associated viewData for Vuex access
				// These will get cleaned up when in Vuex when the active series is cleared
				Object.entries(this.$refs.mprManager.components).forEach(([key, value]) => {
					this.setViewComponent({ index: key, component: value._component })
				})
				tryStartTour()
			})
		},
		async loadSeriesAsync() {
			if (this.series) {
				this.loadVolumeFromSeries({
					series: this.series,
					completion: () => {
						this.loadTitle = 'Loading Images'
						this.setupLevelPresets()
						this.setOnResetCB(this.reset)
						this.setOnInvertCB(this.invert)
						this.setOnMoveSliceCB(this.moveSlice)
					},
				})
			} else {
				// TODO: some fail case error message?
			}
		},
		setupLevelPresets() {
			// Copy Viewer and disable window presets if using Mobile device
			if (!this.isJpeg) {
				this.$store.commit('resetLastLoadedModalityPreset')
				this.$store.dispatch('setWindowLevelPresets', this.setLevels)
			}
		},
		setLevels(windowWidth, windowCenter) {
			this.$refs.mprManager.manuallySetWindowLevels(windowWidth, windowCenter)
		},
		reset() {
			this.$refs.mprManager.reset()
		},
		invert() {
			this.$refs.mprManager.invertVolumeForActiveView()
		},
		moveSlice(sliceStep) {
			this.$refs.mprManager.moveSliceForActiveView(sliceStep)
		},
		onPerformanceIssues(issues) {
			if (issues.hasIssues) {
				this.$store.commit('SET_MPR_PERFORMANCE_ISSUES', issues)
			}
		},
		onWebGLContextLost(event) {
			this.$store.dispatch('addNotification', {
				message: `<strong>Error:</strong><br>
				MPR encountered an issue and stopped.<br>
				You can refresh your browser and try again,
				but if you continue to encounter this error,
				please contact Asteris support.`,
				notificationType: 'error',
				id: 'webGLError',
				duration: 30000,
			})
		},
	},
}
</script>

<style lang="scss">
$text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;

.mpr-manager-container {
	background: black;
	height: 100%;
}
.load-container {
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	pointer-events: none;
	z-index: 200;
}
.load-container * {
	z-index: 200;
	text-shadow: $text-shadow;
}
.load-progress.progress {
	background-color: rgb(120, 130, 140);
	height: 8px;
}
.load-message {
	border-radius: 20px;
	background: rgba(0, 0, 0, 0.8);
	padding: 20px 40px;
	margin: 80px 0;
	display: inline-block;
	position: absolute;
	border: 1px solid rgba(255, 255, 255, 0.2);
	left: 50%;
	transform: translateX(-50%);
}
.load-title {
	color: white;
	text-align: center;
}
.load-subtitle {
	color: rgb(175, 175, 175);
	text-align: center;
	margin-top: 10px;
}
.mpr-row {
	height: 100%;
}
</style>
